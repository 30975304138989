import {computed, defineComponent, PropType, ref, watch} from "vue";
// import UserAvatar from "@/components/UserAvatar"
// import {useI18n} from "vue-i18n"
import {IData, ITableField} from "@/types"
// import Draggable from "vuedraggable"
// import SvgIcon from "@/components/SvgIcon"

export default defineComponent({
  name: 'DataTableSeeker',
  props: {
    items: {
      type: Array as PropType<IData[]>,
      default: () => [],
    },
    columns: {
      type: Array as PropType<ITableField[]>,
      default: () => [],
    },
    // hasTableHead: {
    //   type: Boolean,
    //   default: true,
    // },
    selected: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    // sortBy: {
    //   type: String,
    //   required: false
    // },
    // sortDesc: {
    //   type: Boolean,
    //   default: false,
    //   required: false
    // },
    // fixedRow: {
    //   type: Boolean,
    //   default: false,
    //   required: false
    // },
  },

  emits: [
    'itemClicked',
    // 'headCellClicked',
    // 'changeSortDesc',
    // 'changeSortBy',
    // 'changeColumns',
    // 'resizeColumn',
  ],
  // components: {
  //   UserAvatar,
  //   Draggable,
  //   SvgIcon
  // },
  setup(props, {emit, slots}) { //props, context

    console.log('DataTable setup', props, slots)

    // const { t } = useI18n()
    //
    // const cannotMoveFieldsModel = ref<ITableField[]>([])
    // const canMoveFieldsModel = ref<ITableField[]>([])
    //
    // const setInitialValues = () => {
    //
    //   canMoveFieldsModel.value = []
    //   cannotMoveFieldsModel.value = []
    //
    //   props.columns.forEach(item => {
    //     if (item.can_move == 1) {
    //       canMoveFieldsModel.value.push({...item})
    //     } else {
    //       cannotMoveFieldsModel.value.push({...item})
    //     }
    //   })
    // }
    //
    // setInitialValues()
    // watch(() => props.columns, setInitialValues)

    const clickItemRowHandler = (item: IData, event: any) => {
      emit('itemClicked', item, event)
    }

//     const headCellClickHandler = (event, field) => {
//       console.log('headCellClickHandler', resizeColumnActive, event.target.classList.contains('cell-resizer'))
//       if (resizeColumnActive || event.target.classList.contains('cell-resizer')) {
//         return
//       }
//       emit('headCellClicked', event, field)
//       if (field.can_sort) {
//         if (field.fieldname === props.sortBy) {
//           emit('changeSortDesc', !props.sortDesc)
//         } else {
//           emit ('changeSortBy', field.fieldname, props.sortBy)
//         }
//       }
//     }
//
//     const columnsOrderChanged = () => {
//       const newFields: ITableField[] = []
//       cannotMoveFieldsModel.value.forEach((field, index) => {
//         const newField = {...field}
//         newField.orders = index.toString()
//         newFields.push(newField)
//       })
//       canMoveFieldsModel.value.forEach((field, index) => {
//         const newField = {...field}
//         newField.orders = (cannotMoveFieldsModel.value.length + index).toString()
//         newFields.push(newField)
//       })
//
//       emit('changeColumns', newFields)
//
//     }
//
//     let resizeColumnStartX = 0
//     let resizeColumnActive = false
//     const columnResizeStart = (event) => {
//       console.log('columnResizeStart', event)
//       resizeColumnStartX = event.originalEvent.pageX
//       resizeColumnActive = true
//     }
//     const columnResizeDrag = (event, columnName, eventType) => {
//       console.log('columnResizeDrag', columnName, eventType, event)
//       return true
//
// /*
//       const column = canMoveFieldsModel.value.find(item => item.fieldname === columnName)
//       if (column) {
//         const colElement = ref('datatableCol_' + columnName)
//         colElement.value['style'].width = (parseFloat(column.width) + event.originalEvent.pageX - resizeColumnStartX).toString()
//       }
// */
//     }
//     const columnResizeEnd = (event, columnName) => {
//       console.log('columnResizeEnd', event, event.originalEvent.pageX - resizeColumnStartX)
//       const column = canMoveFieldsModel.value.find(item => item.fieldname === columnName)
//       if (column) {
//         emit('resizeColumn', {
//           ...column,
//           width: (parseFloat(column.width) + event.originalEvent.pageX - resizeColumnStartX).toString()
//         })
//       }
//       requestAnimationFrame(() => {resizeColumnActive = false})
//     }

    // const isFixedRowSelected = computed(() => props.selectedIds.length === 0)
    // const isSelectedId = id => props.selectedIds.includes(id)

    // const isFixedRowSelected = computed(() => props.selected.length === 0)
    const isSelectedId = id => props.selected.includes(id)

    const refItemsContainer = ref()
    const scrollToSelected = () => {
      if (refItemsContainer.value) {
        const selectedItemElement = refItemsContainer.value.querySelector('.data-item.selected[data-id]')
        if (selectedItemElement) {
          selectedItemElement.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "auto",
          })
        }
      }
    }

    return {
      refItemsContainer,
      // t,
      // canMoveFieldsModel,
      // cannotMoveFieldsModel,
      clickItemRowHandler,
      // headCellClickHandler,
      // columnsOrderChanged,
      // columnResizeStart,
      // columnResizeDrag,
      // columnResizeEnd,
      // isFixedRowSelected,
      isSelectedId,
      scrollToSelected,
    }
  }
})
